import { DEFAULT_META_DESCRIPTION } from '@constants/defaults/etc';
import { useAuth } from '@contexts/Auth';
import { KipRole } from '@ts/enums/KipRole';
import { getPageInfoByUrl } from '@utils/helpers/getPageInfoByUrl';
import Head from 'next/head';
import { FC, PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  title: string;
  metaDescription?: string;
}>;

export const KipHead: FC<Props> = ({
  children,
  title,
  metaDescription,
}: Props) => {
  const {
    backendEnv,
    user: { role },
  } = useAuth();
  const { head } = getPageInfoByUrl(window.location.hostname);
  return (
    <Head>
      <title>
        {role === KipRole.root ? `${backendEnv} ` : ''}
        {title} | {head.company}
      </title>
      <link rel="icon" href={head.favicon} />
      <meta
        name="description"
        content={metaDescription ?? DEFAULT_META_DESCRIPTION}
      />
      {children}
    </Head>
  );
};
