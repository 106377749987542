import {
  CUBI_LOGO_IMG_URI,
  AMBAR_LOGO,
  BC4_LOGO,
} from '@constants/routes/assets';
import {
  AMBAR_DOMAIN,
  AMBAR_SUBDOMAIN,
  BC4_DOMAIN,
  BC4_SUBDOMAIN,
} from '@constants/routes/domains';

type LogoType = {
  img: string;
  alt: string;
};
type HeadType = {
  favicon: string;
  company: string;
};

type PageInfoType = {
  logo: LogoType;
  head: HeadType;
};

export const getPageInfoByUrl = (url: string): PageInfoType => {
  switch (true) {
    case url.includes(AMBAR_DOMAIN) || url.includes(AMBAR_SUBDOMAIN):
      return {
        logo: {
          img: AMBAR_LOGO,
          alt: 'Logo Ambar',
        },
        head: {
          favicon: '/favicon-ambar.ico',
          company: 'Ambar Energia',
        },
      };
    case url.includes(BC4_DOMAIN) || url.includes(BC4_SUBDOMAIN):
      return {
        logo: {
          img: BC4_LOGO,
          alt: 'Logo BC4',
        },
        head: {
          favicon: '/favicon-bc4.ico',
          company: 'BC4 Energia',
        },
      };
    default:
      return {
        logo: { img: CUBI_LOGO_IMG_URI, alt: 'Logo CUBi' },
        head: {
          favicon: '/favicon-cubi.ico',
          company: 'CUBi Energia',
        },
      };
  }
};
