/* eslint-disable import/no-default-export */
import { KipHead } from '@components/Layout/KipHead';
import {
  ROUTE_BRANCHES,
  ROUTE_COMPANIES,
  ROUTE_CONSUMPTION,
  ROUTE_EMBEDDED_PAGES,
} from '@constants/routes';
import { useAuth } from '@contexts/Auth';
import { EMBEDS_DATA_KEY } from '@ts/entities/Company';
import { KipRole } from '@ts/enums/KipRole';
import { log } from '@utils/KipLog';
import { useRouter } from 'next/router';

const Index = () => {
  const router = useRouter();
  const { user, isPremium } = useAuth();
  const userIsRoot = Boolean(user?.role === KipRole.root);

  const userQuickSightConfigId =
    user.company?.[EMBEDS_DATA_KEY]?.length !== 0
      ? user.company?.[EMBEDS_DATA_KEY]?.map((item) => item.id).reduce((a, b) =>
          Math.min(a, b)
        )
      : 0;

  const companyFirstQuicksightPanel = `${ROUTE_EMBEDDED_PAGES}?id=${userQuickSightConfigId}`;

  if (!isPremium) {
    router.push(ROUTE_BRANCHES);
  } else if (userIsRoot) {
    log.info(`Kip Index redirecting to ${ROUTE_COMPANIES}`, { userIsRoot });

    router.push(ROUTE_COMPANIES);
  } else if (userQuickSightConfigId !== 0) {
    log.info(`Kip Index redirecting to ${companyFirstQuicksightPanel}`, {
      userIsRoot,
    });
    router.push(companyFirstQuicksightPanel);
  } else {
    log.info(`Kip Index redirecting to ${ROUTE_CONSUMPTION}`, {
      userIsRoot,
    });
    router.push(ROUTE_CONSUMPTION);
  }

  return (
    <>
      <KipHead title="Home" />
    </>
  );
};

export default Index;
